//ROOT
$primary-color: #fff;
$second-color: #000;
$border-color: #D0D0D0;
$accent: #3DB45E;
$dark: #17191D;
$light: #f4f4f4;
$overlay: rgba(23,25,29,0.8);
$ease: all 0.3s ease 0s;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-header: 'Roboto', sans-serif;
$font: 'Montserrat', sans-serif;
@import url('assets/font-icon/css/objectiv-icons.css');
// Reset Css
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  align: baseline;
  font-family: $font; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1.3; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

b, strong {
  font-weight: bold; }

img {
  width: 100%;
  object-fit: cover; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

.not-allowed {
  cursor: not-allowed !important; }
.pointer {
  cursor: pointer; }
// Go to Code
//Header

header {
  background-color: #000000;
  //position: sticky
  position: fixed;
  top: 0;
  //display: flex
  display: block;
  width: 100%;
  align-items: center;
  height: 70px;
  z-index: 999;
  .container-fluid {
    height: 100%;
    .header-row {
      display: flex;
      margin: 0;
      padding-bottom: 11px;
      .logo {
        width: 100%;
        padding-left: 0;
        display: flex;
        align-items: center; }
      .menu {
        display: flex;
        align-items: center;
        padding-top: 10px;
        justify-content: center;
        nav {
          display: flex;
          ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
              padding: 15px;
              a {
                color: $primary-color;
                font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + .30rem);
                font-weight: 400;
                text-transform: uppercase;
                font-family: $font-header; } } } } }
      .social {
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .wrap {
          align-items: center;
          display: flex;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            &:last-child {
              a {
                border-right: 0; } }
            a {
              color: $primary-color;
              font-size: 23px;
              padding: 13.5px 15px;
              border-right: 1px;
              border-color: #595959;
              border-style: solid;
              &:hover {
                opacity: 0.8; } } } } } } } }

// Header white
.header-white {
  background-color: $primary-color;
  border-bottom: 1px solid $border-color;
  position: sticky;
  top: 70px;
  z-index: 9;
  .heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      padding: 10px 0; } }
  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 0;
    //overflow-x: auto
    .talents-block {
      width: max-content;
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      overflow-wrap: unset;
      white-space: nowrap;
      float: left;
      position: relative;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent; } }
    .tag {
      color: #9A9A9A;
      font-size: 14px;
      font-weight: 400;
      padding-right: 20px;
      cursor: pointer;
      display: inline-block;
      overflow-wrap: normal;
      width: max-content;
      &:last-child {
        padding-right: 0; }
      &.active {
        font-weight: 700;
        color: #000; } } } }

.content-page {
  margin: 15px; }

//Media Page
.container-media-page {
  padding: .75rem; }

.media-item {
  position: relative;
  overflow: hidden;
  &:nth-child(3n-2) { //1
    margin: 7.5px 5px 7.5px 7.5px;
    padding: 0; }
  &:nth-child(3n-1) { //2
    margin: 7.5px 5px 7.5px 5px;
    padding: 0; }
  &:nth-child(3n) { //3
    margin: 7.5px 7.5px 7.5px 5px;
    padding: 0; } }
// Event Page
.container-event-page, .container-events-page {
  height: calc(100% - 45px);
  padding: 15px; }
.container-events-row-slider {
  padding-bottom: 15px; }
.eventTagsPointer {
  .tag {
    cursor: default!important; } }
.event-actor-image-block {
  position: relative;
  max-height: 450px;
  overflow: hidden; }
.event-map {
  position: relative;
  margin-top: 15px;
  .event-map-item {
    position: relative;
    overflow: hidden;
    &:nth-child(1) {
      height: 350px !important; } }
  .event-map-block-detail {
    background-color: rgba(255, 255, 255, .9 );
    position: absolute;
    top: 15px;
    padding: 1rem;
    left: 15px;
    width: calc( 100% - 30px );
    z-index: 2;
    .event-content-data-day {
      color: #000;
      font-weight: 600;
      i {
        margin-right: 7px; } }
    .event-content-data-location {
      color: #000;
      font-weight: 600;
      i {
        margin-right: 7px; } } } }
.event-map-item a {
  color: #eee;
  font-size: 12px;
  position: absolute;
  top: 0px; }
.event-info-col {
  padding-left: 0;
  border: 1px solid $border-color; }
.event-info-content {
  padding: 30px 40px; }
.event-info-content-name {
  font-size: 34px;
  font-weight: 700; }
.event-info-content-text {
  padding: 15px 0px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px; }
.event-list-item:focus {
  outline: 0; }
.event-slider-page-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
.event-slider-page-heading h2 {
  font-weight: 700;
  font-size: 24px;
  padding: 25px 15px; }
.events-page-heading {
  width: 100%;
  h2 {
    font-weight: 700;
    font-size: 24px; } }
.slick-event-next, .slick-event-prev {
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
  font-size: 20px;
  color: #000!important;
  cursor: pointer; }
.slick-event-prev {
  right: 70px; }
.slick-event-next {
  right: 10px; }
.event-slider-start {
  width: 100%;
  .event-list-item {
    width: 50%;
    height: 300px;
    padding-right: 15px !important; }
  //.slick-slide.slick-active:nth-child(even)
  //  padding-right: .5rem
  //.slick-slide.slick-active:nth-child(odd)
 }  //  padding-left: .5rem
.event-info-content, .event-slider-info {
  h1 {
    font-weight: 700;
    font-size: 1.6em; }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 15px; }
  ul {
    list-style-type: none;
    padding-bottom: 15px;
    li {
      padding-top: 15px;
      display: flex;
      position: relative;
      left: 25px;
      align-items: flex-start;
      font-size: 14px;
      justify-content: flex-start;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        border: 3px solid #000;
        left: -25px; } } }
  h2 {
    font-size: 16px;
    padding-top: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 3px;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      top: 100%;
      left: 0;
      width: 45%;
      position: absolute;
      height: 3px;
      background-color: #000; } }
  ol {
    padding-top: 15px;
    list-style-type: none;
    counter-reset: num;
    position: relative;
    li {
      position: relative;
      padding-top: 10px;
      font-size: 14px;
      left: 24px;
      &:before {
        content: counter(num) '/';
        counter-increment: num;
        letter-spacing: 2px;
        font-weight: 900;
        position: absolute;
        left: -24px; } } } }
//Video Play Talants
.icon-play {
  line-height: 80px; }
.icon-play:before {
  cursor: pointer; }

// EVENT PAGE
.event-block-calendar {
  .container-calendar {
    max-width: 100%; }
  .event-content-data-day {
    color: #9A9A9A; }
  .event-content-data-location {
    color: #9A9A9A; }
  p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 1.5; } }
.event-slider-info .event-content-data-day, .event-content-data-location {
  color: #9A9A9A;
  p {
    padding-bottom: 0;
    font-size: 14px;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }
.event-content {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  height: 320px;
  width: calc(100% - 400px);
  overflow: hidden;
  .event-content-data {
    padding-top: 10px;
    .event-content-data-location {
      width: calc(100% - 150px); } } }
.event-slider-info {
  height: 250px;
  overflow: hidden;
  cursor: pointer;
  .event-content-data {
    bottom: 0px;
    position: absolute;
    height: 70px;
    width: calc(100% - 150px); }
  a {
    &:hover {
      text-decoration: none; } }
  h1 {
    font-weight: 700;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      text-decoration: none; } }
  .event-text {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 20.8px;
    font-weight: 400;
    font-size: 1em; } }
.event-page-calendar {
  .container-calendar {
    max-width: 100%;
    height: 360px; } }
.event-slider-item {
  display: flex!important;
  flex-wrap: wrap;
  height: 320px;
  &:focus {
    outline: 0; }
  .event-slider-image-block {
    height: 320px;
    width: 400px;
    position: relative;
    cursor: pointer;
    .event-slider-image {
      height: 100%;
      img {
        height: inherit; } } }
  //.event-content-data
  //  padding: 0 15px
  //  position: absolute
  //  bottom: 0
  //  left: 420px
 }  //  width: calc(100% - 440px)
.event-slider {
  position: relative;
  padding: 20px;
  border: 1px solid $border-color;
  height: 360px;
  overflow: hidden;
  .icon-slick-next, .icon-slick-prev {
    position: absolute;
    color: #B7B7B7;
    cursor: pointer; }
  .icon-slick-next {
    right: 30px;
    bottom: 25px;
    z-index: 1; }
  .icon-slick-prev {
    right: 70px;
    z-index: 1;
    bottom: 25px; } }
.slider--counter {
  position: absolute;
  font-weight: 700;
  bottom: 25px;
  right: 110px;
  .total {
    color: #B7B7B7; } }
.event-list-item {
  display: flex;
  padding-right: 0;
  width: 50%; }
.list-item-info-event {
  padding: 20px;
  background-color: #F3F3F3;
  width: 65%;
  .event-content-info-text {
    height: 180px;
    overflow: hidden;
    line-height: 25px;
    margin-bottom: 10px; }
  .event-content-data {
    width: 100%;
    .event-content-data-day {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #9A9A9A; } } }
.list-item-image-event {
  width: 35%;
  position: relative;
  .event-list-image {
    height: 100%;
    width: 100%;
    img {
      height: 100%; } }
  .actor-item-name {
    right: 20px;
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } } }
.list-item-info-event {
  .event-text {
    font-size: 16px;
    line-height: 27px;
    font-weight: 400; } }
.container-events-row-list {
  padding: 15px 0px;
  .event-list-item {
    height: 300px; } }
.list-item-info-event {
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 10px; }
  a {
    color: black; } }

.event-content-grid {
  .event-content-grid-text {
    height: 190px;
    overflow: hidden;
    .event-text {
      line-height: 25.15px; }
    h1 {
      &:hover {
        text-decoration: underline; } } } }
// Header Profile Page dop
.header-profile {
  width: 100%; }
.header-profile-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
.header-profile-heading h2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  padding: 10px 0px 10px 0px; }
.header-profile-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 0; }
.header-profile-social {
  display: flex;
  padding-right: 15px;
  height: 100%; }
.header-profile-social-icon a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  color: $second-color; }
.header-profile-contacts {
  display: flex;
  padding: 0px 25px 0px 30px;
  border-left: 1px solid $border-color;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%; }
.header-profile-contacts a:hover {
  color: $second-color; }
.header-profile-contacts-mail {
  font-size: 14px;
  font-weight: 500;
  color: $second-color;
  padding-bottom: 3px;
  line-height: 1.1;
  &:hover {
    text-decoration: none;
    transform: scale(1.02); } }
.header-profile-contacts-phone {
  font-size: 16px;
  font-weight: 700;
  color: $second-color;
  letter-spacing: 1px;
  &:hover {
    text-decoration: none;
    transform: scale(1.02); } }
.header-profile-agent {
  height: 100%;
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 30px;
  font-weight: 500;
  p {
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 3px; }
  h5 {
    font-weight: 700; } }
//Profile Actor
.actor-item-name {
  &.see-profile {
    left: 0;
    right: 25px;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 30px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.63);
    height: 40px;
    width: 85%; }
  .objective-icon {
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    left: 0;
    position: relative;
    img {
      padding: 8px; } }
  .name {
    background-color: #ffffff;
    margin-left: 15px;
    padding: 10px 15px;
    width: calc(100% - 55px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }
.profile-actor-info {
  border-left: 1px solid $border-color;
  padding: 0; }
.profile-actor-info-wrap {
  padding: 25px 40px;
  position: sticky;
  top: 125px; }
.profile-actor-info-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700; }
// Profile Accordion
.tabs {
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%; }
.tabs::-webkit-scrollbar {
  display: none; }
.tabs input {
  display: none; }
.tab {
  width: 100%;
  color: $second-color;
  overflow: hidden; }

.tab-label {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
  &::after {
    position: relative;
    content: '\e80d';
    font-family: 'objectiv-icons';
    text-align: center;
    transition: .3s all;
    font-size: 16px;
    padding: 10px;
    border: 1px solid $border-color; } }
.tab-content {
  height: 0;
  color: $second-color;
  transition: .3s all; }
.tab-close {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
  &:hover {
    background: #1a252f; } }
.tab:not(:first-child) {
  padding-top: 15px; }
input:checked {
  + .tab-label {
    &::after {
      transition: .3s all;
      content: '\e80a';
      font-family: 'objectiv-icons'; } }

  ~ .tab-content {
    height: auto;
    transition: .3s all; } }
.profile-table {
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400; }
.profile-table-weight {
  font-weight: 700; }
.profile-table table tr:nth-child(odd) {
  background-color: #E9E9E9; }
.profile-table table tr td {
  padding: 10px 10px; }
.profile-actor-info-description {
  h1 {
    font-weight: 700;
    padding-top: 15px;
    font-size: 24px; }
  p {
    font-size: 14px;
    padding-top: 10px;
    font-weight: 400; }
  ul {
    li {
      padding-top: 15px;
      //display: flex
      position: relative;
      left: 25px;
      //align-items: flex-start
      font-size: 14px;
      //justify-content: flex-start
      width: calc(100% - 25px);
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        border: 3px solid $second-color;
        left: -25px;
        top: 18px; } } }
  h2 {
    font-size: 16px;
    padding-top: 15px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 3px;
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      top: 100%;
      left: 0;
      width: 45%;
      position: absolute;
      height: 3px;
      background-color: #000; } }
  ol {
    padding-top: 15px;
    list-style-type: none;
    counter-reset: num;
    position: relative;
    li {
      position: relative;
      padding-top: 10px;
      font-size: 14px;
      left: 24px;
      &:before {
        content: counter(num) '/';
        counter-increment: num;
        letter-spacing: 2px;
        font-weight: 900;
        position: absolute;
        left: -24px; } } } }
.profile-content-page {
  display: flex;
  align-items: flex-start;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    position: absolute;
    right: -3px;
    top: 0;
    background-color: rgba(136, 136, 136, 0.38);
    border-radius: 6px; }
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    width: 6px; }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #000; } }
//Profile-Tabs
.layout-3 {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 1fr 1fr 1fr; }
.layout-2 {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 1fr 1fr; }
.layout {
  display: grid;
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-template-rows: 70px 1fr;
  grid-template-columns: 1fr; }
input[type="radio"] {
  display: none; }

label.nav {
  display: flex;
  position: sticky;
  top: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  background: $primary-color;
  opacity: .6;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  user-select: none;
  transition: background 0.4s, padding-left 0.2s;
  padding-left: 0; }
label.nav:last-child {
  border-right: 0; }
input[type="radio"]:checked + .page + label.nav {
  background: #F3F3F3;
  color: $second-color;
  opacity: 1; }

label.nav span {
  padding-left: 0px;
  position: relative; }

.page {
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-end: span 3;
  display: flex; }

.page-contents > * {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s, transform 0.2s; }

.page-contents > *:nth-child(1) {
  transition-delay: .2s; }

.page-contents > *:nth-child(2) {
  transition-delay: .4s; }

.page-contents > *:nth-child(3) {
  transition-delay: .6s; }

.page-contents > *:nth-child(4) {
  transition-delay: .8s; }

.page-contents > *:nth-child(5) {
  transition-delay: 1s; }

.page-contents > *:nth-child(6) {
  transition-delay: 1.2s; }

.page-contents > *:nth-child(7) {
  transition-delay: 1.4s; }

.page-contents > *:nth-child(8) {
  transition-delay: 1.6s; }

.page-contents > *:nth-child(9) {
  transition-delay: 1.8s; }

.page-contents > *:nth-child(10) {
  transition-delay: 2s; }


input[type="radio"] + .page {
  transition: transform 0.2s;
  transform: translateX(100%); }

input[type="radio"]:checked + .page {
  transform: translateX(0%); }

input[type="radio"]:checked + .page .page-contents > * {
  opacity: 1;
  transform: translateY(0px); }

.profile-media {
  overflow: hidden;
  position: relative;
  flex: 0 0 calc( 50% - 7.5px);
  max-width: calc( 50% - 7.5px);
  height: 100%;
  display: block;
  padding: 0;
  margin-bottom: 15px;
  &.video {
    background-size: cover;
    position: relative;
    overflow: hidden; }
  &:nth-child(2n-1) {
    margin-right: 7.5px; }
  &:nth-child(2n) {
    margin-left: 7.5px; } }
.page-contents {
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  padding: 15px 15px 0px 15px;
  .grid-item {
    margin-bottom: 15px;
    width: calc( 50% - 15px );
    &:nth-child(2n - 1) {
      padding-right: 7.5px; }
    &:nth-child(2n) {
      padding-left: 7.5px; } } }
.page-contents img {
  width: 100%;
  object-fit: cover; }
.talants-item-name {
  top: 82%!important; }
// Actor Item
.actor-item img {
  width: 100%;
  object-fit: cover; }
.profile-page {
  z-index: 2; }
.actor-item img:nth-child(2) {
  display: none; }
.actor-item-name {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  line-height: 20px;
  bottom: 20px;
  display: flex;
  left: 0;
  right: 50px;
  z-index: 3;
  color: $second-color; }
.container-actors {
  padding: 15px 7.5px 0 7.5px; }
.main-row-actors {
  margin: 0; }
.actor-item {
  overflow: hidden;
  position: relative; }
.actor-item:nth-child(4n-3) { //1
  margin: 0px 5px 10px 7.5px;
  padding: 0; }
.actor-item:nth-child(4n) { //4
  padding: 0;
  margin: 0px 7.5px 10px 5px; }
.actor-item:nth-child(4n-2) { //2
  margin: 0px 5px 10px 5px;
  padding: 0; }
.actor-item:nth-child(4n-1) { //3
  margin: 0px 5px 10px 5px;
  padding: 0; }
.talants-item {
  background-size: cover; }
@media screen and (min-width: 992px) {
  //.event-list-item
  //  flex: 0 0 50% !important
  //  max-width: 50% !important
  .primary-container, .container-event-page {
    padding-top: 15px; }
  .actor-item-slider {
    height: calc( 20.15vw - 18.5px );
    div {
      height: 100%;
      img {
        height: inherit; } }
    .slick-list {
      .slick-track {
        .slick-slide {
          height: calc( 20.15vw - 18.5px );
          div {
            height: 100%;
            img {
              height: inherit; } } } } } }
  #profile-page {
    overflow: hidden; }
  .event-list-item:nth-child(2n-1) {
    padding-right: 0; }
  .event-list-item:nth-child(2n) {
    padding-right: 15px; }
  .profile-tabs .layout .profile-content-page {
    overflow-y: auto;
    height: 72vh; }
  .profile-tabs .layout-2 .profile-content-page {
    overflow-y: auto;
    height: 72vh; }
  .profile-tabs .layout-3 .profile-content-page {
    overflow-y: auto;
    height: 72vh; }
  .profile-actor-info-wrap {
    padding: 25px 40px;
    position: sticky;
    top: 125px;
    overflow-y: auto;
    height: 79vh; }
  .actor-item {
    flex: 0 0 calc( 25% - 11.5px );
    max-width: calc( 25% - 11.5px ); }
  .talants-item {
    flex: 0 0 calc( 33.333333% - 15px);
    max-width: calc( 33.333333% - 15px); } }

@media (min-width: 768px) and (max-width: 992px) {
  .video-item {
    .actor-item-slider {
      height: 300px;
      div {
        height: inherit; }
      .slick-list {
        .slick-track {
          .slick-slide {
            height: 300px;
            div {
              height: 100%; } } } } } }
  .event-info-content-name {
    font-size: 24px; }
  .event-info-content-text {
    font-size: 14px;
    padding: 10px 0px; }
  .event-info-content {
    padding: 20px 30px;
    h1 {
      font-size: 20px;
      padding-bottom: 10px; }
    p {
      font-size: 14px;
      padding-bottom: 10px; }
    ul {
      padding-bottom: 10px;
      li {
        padding-top: 10px; } }
    ol {
      padding-top: 10px; }
    h2 {
      font-size: 15px; } }
  .list-item-image-event .event-actor-name {
    //width: 60%
    font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + .7rem); }
  .event-slider-image-block .event-actor-name h2:after {
    width: 110%; }
  .event-slider-image-block {
    .event-slider-image {
      height: 100%; } }
  .event-slider .event-content-data-day {
    font-size: 14px;
    i {
      font-size: 16px; } }
  .event-slider .event-content-data-location {
    padding-top: 10px;
    align-items: flex-start;
    font-size: 14px;
    padding-bottom: 40px;
    i {
      font-size: 16px; } }
  .actor-item {
    flex: 0 0 calc( 33% - 12.5px);
    max-width: calc( 33% - 12.5px); }
  .header-profile-social {
    padding-right: 15px; }
  .header-profile-agent {
    padding: 0px 15px; }
  .header-profile-agent p {
    font-size: 15px; }
  .header-profile-contacts {
    padding: 0px 15px; } }
@media screen and ( min-width: 768px ) {
  .event-image-map {
    padding-left: 0; }
  .header-profile-social-media {
    display: none; }
  .media-item {
    flex: 0 0 calc( 50% - 12.5px);
    max-width: calc( 50% - 12.5px); } }
@media screen and (max-width: 768px) {
  .event-slider {
    height: auto; }
  .media-item {
    flex: 0 0 calc( 100% - 12.5px);
    max-width: calc( 100% - 12.5px); }
  .event-info-content {
    padding: 20px 15px; }
  .event-info-col {
    padding: 0;
    margin-top: 15px; }
  .event-image-map {
    padding: 0; }
  .list-item-image-event .event-actor-name {
    font-size: 17px; }
  .event-slider {
    .event-content-data-day {
      font-size: 14px;
      i {
        font-size: 18px; } }
    .event-content-data-location {
      font-size: 14px;
      line-height: 22px;
      padding-bottom: 5px;
      align-items: flex-start;
      i {
        font-size: 18px; } } }
  .event-slider-start {
    flex-direction: column;
    .event-list-item {
      margin-bottom: 15px;
      width: 100%;
      height: auto !important;
      display: inline-block !important; } }
  .list-item-image-event {
    width: 100%;
    height: 300px; }
  .list-item-info-event {
    width: 100%;
    height: 250px;
    h1 {
      font-size: 18px;
      line-height: 20px; }
    .event-content-info-text {
      height: 140px; }
    .event-text {
      font-size: 14px; }
    .event-content-data-day {
      font-size: 14px;
      i {
        font-size: 16px; } } }
  .container-events-row-list {
    .event-list-item {
      height: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      .list-item-image-event {
        width: 100%;
        height: 300px; }
      .list-item-info-event {
        width: 100%;
        height: 300px; } } }
  .list-item-info-event .event-content-data-location {
    font-size: 14px;
    align-items: flex-start;
    i {
      font-size: 16px; } }
  //Header Actors
  .header-profile-social-media {
    display: flex;
    height: 100%; }
  .header-profile-heading h2 {
    font-size: 20px;
    padding: 5px 0px; }
  .header-profile-social-media {
    .header-profile-social-icon-media:last-child {
      a {
        padding-right: 0; } }
    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      padding-right: 5px;
      img {
        height: 22px;
        width: auto; } } }
  .actor-item-name {
    //padding: 12px 10px 12px 13px
    font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + 1.1rem); }
  .header-profile-heading {
    justify-content: space-between; }
  .header-profile-info {
    padding: 0;
    justify-content: space-between; }
  .header-profile-social {
    display: none; }
  .header-profile-agent {
    border-left: 0;
    padding: 0px 15px 5px 15px;
    width: 100%;
    border-right: 1px solid $border-color; }
  .header-profile-agent p {
    font-size: 14px; }
  .header-profile-agent h5 {
    font-size: 14px; }
  .header-profile-contacts {
    border-left: 0;
    padding: 0px 15px 5px 15px;
    width: 100%; }
  .header-profile-contacts-mail {
    font-weight: 400;
    font-size: 12px; }
  .header-profile-contacts-phone {
    font-size: 15px; } }

// HOVER ITEM
.actor-item-image:hover .actor-item-name:before {
  background-color: rgba(0, 0, 0,.7 );
  transition: all 0.3s; }
.actor-item-image:hover .actor-item-name {
  color: $primary-color; }

.actor-item:hover {
  .actor-item-name {
    &.see-profile {
      color: $primary-color; } } }
.actor-item:hover .actor-item-name h2:before {
  background-color: rgba(0, 0, 0,.5 ); }
//HOVER ARROWS
.actor-item:after {
  content: '\e803';
  font-family: "objectiv-icons";
  position: absolute;
  transform: rotate(-90deg);
  color: #606060;
  transition: all .4s ease;
  top: 22px;
  left: 30px;
  font-size: 40px;
  opacity: 0;
  transition: all .4s ease; }
.actor-item:before {
  content: '\e803';
  font-family: "objectiv-icons";
  position: absolute;
  transform: rotate(90deg);
  color: #606060;
  transition: all .4s ease;
  bottom: 22px;
  right: 30px;
  font-size: 40px;
  opacity: 0;
  transition: all .4s ease; }
.actor-item:hover:before {
  content: '\e803';
  font-family: "objectiv-icons";
  position: absolute;
  transform: rotate(90deg);
  color: #606060;
  transition: all .4s ease;
  bottom: 22px;
  right: 30px;
  font-size: 40px;
  z-index: 1;
  opacity: 1;
  transition: all .4s ease; }
.actor-item:hover:after {
  content: '\e803';
  font-family: "objectiv-icons";
  position: absolute;
  transform: rotate(-90deg);
  color: #606060;
  transition: all .4s ease;
  top: 22px;
  left: 30px;
  font-size: 40px;
  opacity: 1;
  transition: all .4s ease; }
#mainBlock {
  position: relative;
  margin-bottom: 45px;
  padding-bottom: 45px;
  top: 70px;
  height: calc(100% - 45px); }
.event-info-col {
  overflow: hidden; }
// Footer
#footer {
  background-color: $second-color;
  padding: 15px 0px 15px 0px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  max-height: 45px; }
.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center; }
.footer-agenstv p {
  color: #999999;
  font-size: 12px; }
.footer-design {
  display: flex;
  align-items: center; }
.footer-design a {
  display: flex; }
.footer-design p {
  color: #999999;
  font-size: 12px;
  padding-right: 10px;
  line-height: 14px; }

// Event Block
.primary-row-video {
  width: 640px;
  height: 360px;
  position: relative;
  iframe {
    width: 100%;
    height: 100%; }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }
  .controls {
    position: absolute;
    bottom: 5px;
    right: 20px;
    opacity: .5;
    button {
      width: 20px;
      padding: 0;
      background: none;
      border: none; } } }
.event-block {
  height: 360px;
  border: 1px solid #D0D0D0;
  overflow: hidden;
  width: calc(100% - 640px);
  margin-left: 8px;
  padding: 20px;
  .event-block-post {
    height: 320px;
    .icon-slick-prev {
      position: absolute;
      bottom: 5px;
      right: 45px;
      color: #B7B7B7;
      cursor: pointer; }
    .icon-slick-next {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: #B7B7B7;
      cursor: pointer; }
    .slider--counter {
      right: 85px;
      bottom: 5px; }
    .event-content-data-location {
      width: calc(100% - 150px); } } }
.event-content-actor {
  img {
    width: 250px;
    height: 200px; } }
.event-post-content {
  height: 320px;
  display: flex !important;
  margin: 0;
  .event-content-actor {
    cursor: pointer; }
  .event-content-text {
    cursor: pointer; } }
.event-content-heading {
  font-weight: 700;
  font-size: 1.6em;
  a {
    &:hover {
      text-decoration: none; } }
  h1 {
    font-weight: 700;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      text-decoration: none; } } }
.event-content-description {
  font-size: 1em;
  margin-top: 10px;
  display: inline-block; }
.event-content-text {
  padding-left: 15px;
  width: calc(100% - 250px);
  height: 250px;
  overflow: hidden;
  margin-bottom: 10px; }
.event-content-actor-name {
  font-weight: 500;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px; }
.event-content-actor {
  height: 250px;
  width: 250px; }
.event-content-data {
  height: 70px;
  padding-left: 0px;
  padding-right: 0px; }

.event-content-data-day, .event-content-data-location {
  display: flex;
  align-items: center;
  color: #9A9A9A; }
.event-content-data-day i, .event-content-data-location i {
  font-size: 18px; }
.event-content-data-location {
  padding-top: 10px; }
.primary-main-row {
  padding-left: 0;
  height: 360px; }
.primary-row {
  margin: 0;
  display: flex; }

// Burger Menu
.header-row {
  .media-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .main-nav {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: center;
      background: transparent;
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      transition: all .375s;
      &.is-open {
        opacity: 1;
        z-index: 100;
        visibility: visible;
        a {
          opacity: 1;
          transform: translateY(0); }
        //&::before
 }        //  transform: skew(-14deg) translateX(0)
      //&::before
      //  content: ''
      //  position: absolute
      //  top: 0
      //  bottom: 0
      //  left: 0
      //  right: -25px
      //  background: #000
      //  transform-origin: 0 0
      //  transform: skew(-14deg) translateX(-120%)
      //  transition: all .275s .1s
      .nav-bg {
        height: 100%;
        width: calc(100% + 25px);
        position: absolute;
        z-index: 9999;
        background-color: #000000;
        transform: skew(-14deg);
        transition: all .275s .1s;
        transform-origin: 0 0; }
      ul {
        position: relative;
        z-index: 99999;
        display: inline-flex;
        flex-direction: column;
        height: 100%;
        list-style: none;
        padding: 0;
        /* Should be 100%, but we have a notice message :D */
        align-items: flex-end;
        justify-content: center;
        transform: translateX(-18%) skew(-16deg);
        li {
          list-style: none;
          padding: 0;
          display: block;
          text-align: right;
          transform: skew(16deg);
          &.social {
            display: inline-flex;
            a {
              &:hover {
                opacity: 0.8; } } }
          a {
            opacity: 0;
            transform: translateY(-10px);
            display: block;
            padding: 20px 0;
            color: #fff;
            font-size: 1.4em;
            text-decoration: none;
            font-weight: bold;
            &:hover {
              text-decoration: underline; } }
          &:nth-child(1) a {
            transition: all 275ms 175ms; }
          &:nth-child(2) a {
            transition: all 275ms 225ms; }
          &:nth-child(3) a {
            transition: all 275ms 275ms; }
          &:nth-child(4) a {
            transition: all 275ms 325ms; }
          &:nth-child(5) a {
            transition: all 275ms 375ms; }
          &:nth-child(6) a {
            transition: all 275ms 425ms; }
          &:nth-child(7) a {
            transition: all 275ms 475ms; } } } }
    .open-main-nav {
      position: relative;
      top: 10px;
      right: 0;
      z-index: 1000;
      background: none;
      border: 0;
      cursor: pointer;
      &:focus {
        outline: none; } }
    #burger {
      height: 100%; }
    .burger {
      position: relative;
      display: block;
      width: 28px;
      height: 4px;
      background: #fff;
      transition: all .275s;
      &:after, &:before {
        content: '';
        display: block;
        height: 100%;
        background: #fff;
        transition: all .275s;
        height: 4px; }
      &:after {
        transform: translateY(-12px) skew(0deg); }
      &:before {
        transform: translateY(-16px) skew(0deg); } }
    .is-open {
      .burger {
        position: relative;
        display: block;
        width: 28px;
        height: 4px;
        background: #fff;
        transition: all .275s;
        &:after, &:before {
          content: '';
          display: block;
          height: 100%;
          background: #fff;
          transition: all .275s;
          height: 4px; }
        &:after {
          transform: translateY(-12px) skew(0deg); }
        &:before {
          transform: translateY(-16px) skew(0deg);
          transform: rotate(90deg); } } }
    .is-open .burger {
      transform: skew(0deg) translateY(-8px) rotate(-45deg);
      &:before {
        transform: translateY(0px) skew(0deg) rotate(90deg); }
      &:after {
        transform: translateY(-12px) translateX(10px) skew(-20deg);
        opacity: 0; } } } }
.container-calendar {
  position: relative;
  padding: 15px 20px 15px 20px;
  background-color: $second-color;
  margin: auto 0 auto auto;
  height: 326px; }
// MEDIA GET
@media screen and (max-width: 992px) {
  .page-contents {
    overflow: hidden; }
  .profile-row-item {
    overflow: hidden; }
  input[type="radio"] + .page {
    position: absolute;
    height: min-content;
    opacity: 0; }
  input[type="radio"]:checked + .page {
    position: relative;
    height: min-content;
    opacity: 1; }
  .container-events-page, .container-event-page {
    padding: 15px; }
  header {
    .container-fluid {
      .header-row {
        height: 100%;
        justify-content: space-between;
        .logo {
          flex: 0 0 auto;
          max-width: fit-content;
          height: 100%; }
        .media-menu {
          flex: 0 0 auto;
          max-width: fit-content;
          height: 100%; } } } }
  .actor-item-slider {
    height: calc(28vw - 22px);
    div {
      height: 100%;
      img {
        height: inherit; } }
    .slick-list {
      .slick-track {
        .slick-slide {
          height: calc( 28vw - 22px );
          div {
            height: 100%;
            img {
              height: inherit; } } } } } }
  .media-item {
    height: fit-content; }
  .primary-main-row {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    min-height: 400px;
    display: none; }
  .primary-row-video video {
    width: 100%; }
  header {
    .container-fluid {
      .header-row {
        .social {
          display: none; }
        .menu {
          display: none;
          nav {
            display: none; } } } } }
  .footer-agenstv p {
    font-size: 10px; }
  .footer-design p {
    display: none; }
  .actor-item-name {
    font-size: calc( (100vw - 60rem)/(80 - 30) * (1.5 - 1) + .90rem);
    right: 30px; }
  .profile-actor-info-wrap {
    padding: 25px 15px; } }
//HOVER SLIDER
@media screen and (min-width: 993px) {
  .header-row {
    .media-menu {
      display: none; } } }
@media screen and (min-width: 992px) {
  .event-post-content {
    overflow: hidden; }
  .media-item {
    flex: 0 0 calc( 33.333333% - 12px);
    max-width: calc( 33.33333% - 12px); } }
@media (min-width: 992px) and (max-width: 1200px) {
  .header-white {
    top: 64px; }
  header {
    height: 64px;
    .container-fluid {
      .header-row {
        .logo {
          flex: 0 0 20%;
          max-width: 20%;
          a {
            width: 183.59px; } }
        .menu {
          flex: 0 0 60%;
          max-width: 60%; }
        .social {
          flex: 0 0 20%;
          max-width: 20%; } } } }
  #mainBlock {
    top: 64px; }
  .video-item.video {
    .video-name {
      font-size: 15px; } }
  .event-block-post {
    max-width: 100%;
    flex: 0 0 100%; }
  .event-slider-start {
    .event-list-item {
      height: 240px;
      .list-item-info-event {
        .event-content-info-text {
          height: 130px; } } } }
  .list-item-image-event {
    width: 45%; }
  .list-item-info-event {
    padding: 15px;
    width: 55%;
    .event-text {
      font-size: 15px;
      line-height: 20px; }
    h1 {
      font-size: 18px;
      line-height: 20px; }
    .event-content-data-day {
      padding-top: 10px;
      font-size: 13px;
      i {
        font-size: 16px; } }
    .event-content-data-location {
      font-size: 13px;
      padding-top: 10px;
      i {
        font-size: 16px; } } }
  .event-slider-image-block .event-slider-image {
    height: 100%; }
  .event-slider-image-block .event-slider-image .actor-item-image {
    height: 100%; }
  .event-slider-image-block .event-actor-name {
    font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + .55rem); }
  .event-slider-info .event-content-data-location {
    padding-bottom: 30px; }
  .event-slider .slider--counter {
    bottom: 20px; } }
@media screen and (max-width: 576px) {
  .slick-event-next, .slick-event-prev {
    font-size: 16px;
    top: -44px; }
  .slick-event-prev {
    right: 45px; }
  .slick-event-next {
    right: 5px; }
  .page.skills, .page.profile-video {
    .page-contents {
      display: flex;
      .profile-media {
        flex: 0 0 100%;
        max-width: 100%;
        &:nth-child(2n-1) {
          margin-right: 0; }
        &:nth-child(2n) {
          margin-left: 0; } }
      .video-item {
        width: 100%; } } }
  .profile-actor-info-description {
    ol, ul {
      padding-right: 20px; } }
  .actor-item {
    flex: 0 0 calc( 100% - 12.5px);
    max-width: calc( 100% - 12.5px); }
  .actor-item-slider {
    height: calc( 71vw - 30px );
    div {
      height: 100%; }
    .slick-list {
      .slick-track {
        .slick-slide {
          height: calc( 71vw - 30px );
          div {
            height: 100%; } } } } }
  .event-info-content {
    padding: 25px 15px; }
  .event-content-text {
    padding-left: 0; }
  label.nav {
    font-size: 14px; }
  .layout-3, .layout-2, .layout {
    grid-template-rows: 60px 1fr;
    .header-white {
      .heading {
        h2 {
          padding: 5px 0; } }
      .tags {
        padding: 5px 15px 10px 15px;
        justify-content: flex-start; } } }
  .talants-item {
    flex: 0 0 calc( 100% - 15px);
    max-width: calc( 100% - 15px); }
  .header-white {
    .tags {
      padding-bottom: 15px;
      justify-content: flex-start;
      padding-left: 15px; } }
  .header-white {
    .heading {
      h2 {
        line-height: 30px; } } }
  .actor-item {
      flex: 0 0 calc( 50% - 12.5px );
      max-width: calc( 50% - 12.5px ); } }
@media (min-width: 576px) and ( max-width: 992px ) {
  .skills, .profile-video {
    .page-contents {
      .profile-media {
        .video-item {
          width: 100%; } } } }
  .list-item-image-event .event-actor-name {
    font-size: 14px; }
  .menu-nav-ul li a {
    font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + .2rem); } }
@media (min-width: 576px) {
  .event-item-slider {
    display: flex!important; } }
@media (min-width: 1200px) and (max-width: 1600px) {
  .header-white {
    top: 75px; }
  header {
    height: 75px;
    .container-fluid {
      .header-row {
        .logo {
          flex: 0 0 20%;
          max-width: 20%;
          a {
            width: 225px; } }
        .menu {
          flex: 0 0 60%;
          max-width: 60%; }
        .social {
          flex: 0 0 20%;
          max-width: 20%; } } } }
  #mainBlock {
    top: 75px; }
  .list-item-image-event {
    width: 45%; }
  .list-item-info-event {
    width: 55%;
    h1 {
      font-size: 20px;
      line-height: 24px; }
    .event-text {
      font-size: 15px;
      line-height: 25px; }
    .event-content-data-day {
      padding-top: 15px; }
    .event-content-data-location {
      padding-top: 10px; } } }
@media (min-width: 992px) and (max-width: 1380px) {
  header .container-fluid .header-row .menu nav ul li a {
    font-size: calc( (100vw - 10rem)/(80 - 30) * (1.5 - 1) + .20rem); }
  header .container-fluid .header-row .social .wrap .icon a {
    padding: 7px 10px;
    font-size: 18px; } }
@media screen and (min-width: 1600px) {
  header {
    height: 98px;
    .container-fluid {
      .header-row {
        .logo {
          flex: 0 0 20%;
          max-width: 20%;
          a {
            width: 313.5px; } }
        .menu {
          padding-top: 12px;
          flex: 0 0 60%;
          max-width: 60%; }
        .social {
          flex: 0 0 20%;
          max-width: 20%; } } } }
  #mainBlock {
    top: 98px; }
  .header-white {
    top: 98px; } }
@media (max-width: 400px) {
  .video-item.video .video-play {
    font-size: 50px; }
  .slick-event-next, .slick-event-prev {
    display: none!important; } }
@media (max-width: 1279px) {
  .container-fluid {
    &.primary-container {
      display: none; } } }
@media (max-width: 640px) {
  .actor-item-name {
    font-size: calc( (100vw - 30rem)/(80 - 30) * (1.5 - 1) + .90rem);
    right: 80px; }
  //.event-slider-start
  //  .event-list-item
  //    height: 240px
  //    display: flex !important
  //    .list-item-info-event
  //      .event-content-info-text
 }  //        height: 130px

@media (max-width: 1451px) {
  .actor-item-name {
    right: 25px;
    &.see-profile {
      right: 25px; }
    .name {
      font-size: 12px;
      padding: 10px; }
    &.see-profile {
      font-size: 14px; } } }
@media (max-width: 1335px) {
  .actor-item-name {
    &.see-profile {
      font-size: 12px; } } }
@media (max-width: 1245px) {
  .actor-item-name {
    .name {
      font-size: 12px; } } }
@media (max-width: 1200px) {
  .actor-item {
    flex: 0 0 calc(33% - 11.5px);
    max-width: calc(33% - 11.5px); }
  .actor-item-slider {
    height: calc( 28vw - 22px);
    .slick-list {
      .slick-track {
        .slick-slide {
          height: calc( 28vw - 22px); } } } } }
@media (max-width: 915px) {
  .actor-item-name {
    &.see-profile {
      font-size: 11px;
      padding: 12px 15px; } } }
@media (max-width: 840px) {
  .actor-item {
    flex: 0 0 calc(50% - 11.5px);
    max-width: calc(50% - 11.5px); }
  .actor-item-slider {
    height: 400px;
    .slick-list {
      .slick-track {
        .slick-slide {
          height: 400px; } } } }
  .actor-item-name {
    &.see-profile {
      font-size: 14px;
      padding: 12px 30px; }
    .name {
      font-size: 16px; } } }
@media (max-width: 576px) {
  .actor-item {
    flex: 0 0 calc(100% - 11.5px);
    max-width: calc(100% - 11.5px); } }
@media (min-width: 991px) and (max-width: 1440px) {
  .video-item {
    &.video {
      .video-play {
        font-size: 50px; } } } }
@media (max-width: 800px) {
  .event-slider {
    height: auto;
    .event-slider-item {
      height: auto;
      .event-slider-image-block {
        width: 100%;
        height: 400px; }
      .event-content {
        width: 100%;
        padding: 10px 0 0; }
      .event-slider-info {
        width: 100%;
        .event-text {
          margin-top: 17px; } }
      .event-content-data {
        width: 100%;
        position: unset; } } } }
@media (max-width: 500px) {
  .event-slider {
    .event-slider-item {
      .event-slider-image-block {
        height: 350px; } } }
  .event-slider-start {
    .event-list-item {
      display: inline-block !important;
      height: auto;
      .list-item-image-event {
        width: 100%;
        height: 300px; }
      .list-item-info-event {
        width: 100%;
        height: 250px;
        h1 {
          line-height: 22px; }
        .event-content-info-text {
          height: 120px;
          .event-text {
            line-height: 22px; } } } } } }
@media (max-width: 380px) {
  .event-slider {
    .event-slider-item {
      .event-slider-image-block {
        height: 275px; } } } }


.content__section {
  display: grid;
  grid-template-columns: 0 1fr 0;
  grid-template-areas: ". playlist__one ." ". playlist__two ."; }

.playlist__one {
  grid-area: playlist__one; }

.playlist__two {
  grid-area: playlist__two; }

.playlist__title {
  margin: 30px 0; }

.videos {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: .75rem; }

.video {
  //height: 360px
  grid-column-start: span 3;
  position: relative;
  transition: $ease;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $second-color;
  &.media-video {
    padding-top: 56.25%; }
  &:hover {
    transform: scale(1.02); }
  i {
    cursor: pointer;
    font-size: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $primary-color;
    opacity: 0.85;
    transition: $ease;
    &:hover {
      opacity: 1; } } }

.vbox-container {
  video {
    width: 100%;
    height: 100%; } }

.profile-video {
  padding: .75rem;
  .video {
    grid-column-start: span 6;
    background-color: transparent; } }

.skills {
  padding: .75rem;
  .video {
    grid-column-start: span 6;
    background-color: transparent; } }

@media only screen and (max-width: 1451px) {
  .video {
    grid-column-start: span 4; } }
@media only screen and (max-width: 1200px) {
  .video {
    grid-column-start: span 6; } }
@media only screen and (max-width: 800px) {
  .video {
    grid-column-start: span 12; } }
@media only screen and (max-width: 700px) {
  .profile-video {
    .video {
      grid-column-start: span 12; } }
  .skills {
    .video {
      grid-column-start: span 12; } } }
@media (max-width: 1300px) and (min-width: 992px) {
  .profile-video {
    .video {
      grid-column-start: span 12; } }
  .skills {
    .video {
      grid-column-start: span 12; } } }
